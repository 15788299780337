import * as React from 'react';
import { useParams, Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';

import { ClassName } from 'src/lib';

import { generatePath } from 'src/utils';

import { Marquee } from 'src/components/marquee';

import * as ROUTES from 'src/router/routes';

import './intl';

export interface IGiftPropTypes {
	itemId: string;
	type: 'unknown' | 'digital' | 'physical' | 'promocode';
	title: string;
	media: string;
	code?: string;
}

const cn = ClassName.create('gift', require('./style.less'));

export function Gift(props: IGiftPropTypes): React.ReactElement<IGiftPropTypes> {
	const intl = useIntl();
	const params = useParams<{
		organizationId: string;
		resourceId: string;
	}>();

	const isPromocodeAvailable = props.type === 'promocode' && !!props.code;
	const isPhysicalGift = props.type === 'physical';

	const card = (
		<div className={cn.get([props.type])}>
			<div className={cn.get('media')}>
				<div
					className={cn.get('media', 'item', [!props.media && 'stub'])}
					style={{
						backgroundImage: `url("${props.media || require('./assets/stub.png')}")`,
					}}
				></div>
			</div>
			<div className={cn.get('title')}>
				<Marquee
					className={cn.get('title', 'marquee', [isPromocodeAvailable && 'dismissible'])}
					duration={1000}
					isScrolling
				>
					{props.title}
				</Marquee>
				{isPromocodeAvailable && <div className={cn.get('title', 'promocode')}>{props.code}</div>}
			</div>
			<div className={cn.get('hint')}>
				<div
					className={cn.get('hint', 'label', [
						['physical', 'promocode'].includes(props.type) && 'dismissible',
						isPromocodeAvailable && 'original',
					])}
				>
					<Marquee duration={1000} isScrolling>
						{isPromocodeAvailable
							? props.code
							: intl.formatMessage({ id: ['gift.type', props.type].join('.'), defaultMessage: '' })}
					</Marquee>
				</div>
				{props.type === 'promocode' && (
					<div className={cn.get('hint', 'promocode')}>
						<Marquee duration={1000} isScrolling>
							{isPromocodeAvailable
								? intl.formatMessage({ id: 'gift.promocode.copy' })
								: intl.formatMessage({ id: 'gift.promocode.waiting' })}
						</Marquee>
					</div>
				)}
				{props.type === 'physical' && (
					<div className={cn.get('hint', 'physical')}>
						<Marquee duration={2000} isScrolling>
							{intl.formatMessage({ id: 'gift.physical.address' })}
						</Marquee>
					</div>
				)}
			</div>
		</div>
	);

	if (isPhysicalGift) {
		return (
			<Link
				to={generatePath(ROUTES.PROFILE_ACCOUNT, {
					organizationId: params.organizationId || ORGANIZATION_ID,
					resourceId: params.resourceId || '',
				})}
			>
				{card}
			</Link>
		);
	}

	if (isPromocodeAvailable) {
		return (
			<CopyToClipboard
				text={props.code ?? 'Error'}
				options={{ format: 'text/plain' }}
				onCopy={() => {
					toast.success(intl.formatMessage({ id: 'gift.promocode.copied' }));
				}}
			>
				{card}
			</CopyToClipboard>
		);
	}

	return card;
}
